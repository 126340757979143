// File: js/src/view/renderizarFeedbacks.js

export function scstfm_renderizarFeedbacks(targetId, message, style) {
  const errorElement = document.querySelector(targetId);
  if (errorElement) {
    errorElement.innerHTML = "";
    const messageElement = `<span style="${style}">${message}</span>`;
    errorElement.innerHTML = messageElement;
  }
}
