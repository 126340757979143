// File: src/model/atualizarCreditos.js
import { scstfm_logDebugFront } from "../utils/logDebugFront.js";

export async function scstfm_atualizarCreditos(email) {
  const data = new FormData();
  data.append("action", "scstfm_atualizar_credits");
  data.append("nonce", window.ajax_object.ajax_nonce);
  data.append("email", email);

  try {
    const response = await fetch(window.ajax_object.ajax_url, {
      method: "POST",
      credentials: "same-origin",
      body: data,
    });
    const result = await response.json();
    scstfm_logDebugFront("Retorno atualizarCreditos: ", result);

    if (result?.data?.validated) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    scstfm_logDebugFront("Erro ao atualizar créditos: ", error);
    return false;
  }
}
