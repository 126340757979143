// File: src/view/renderizarFormHubs.js
import { scstfm_logDebugFront } from "../utils/logDebugFront.js";
import { scstfm_validarEmail } from "../model/validarEmail.js";
import { scstfm_checarCreditos } from "../model/checarCreditos.js";
import { scstfm_renderizarFeedbacks } from "./renderizarFeedbacks.js";
import scstfm_navegacaoSecoes from "../controllers/gerenciarNavegacaoSecoes.js";

export const formInstances = {};

/**
 * Renderiza 2 formulários do HubSpot (IDs fixos).
 *
 * - FORM1 (e-mail):
 *   - Toda vez que o usuário digita, salvamos o e-mail em localStorage["scstfm_temp_email"].
 *   - Se a validação do plugin reprovar, recriamos o form para tentar outra vez.
 *   - Se aprovar, salvamos o e-mail final em localStorage["email"] (para main.js).
 * - FORM2 (SLA): Similar, mas geralmente apenas exibe o form final e oculta "Obrigado".
 */
export function scstfm_renderizarFormHubs(formId1ElementTarget, formId2ElementTarget) {
  const form1 = {
    portalId: window.ajax_object?.hubspot_portal || "",
    formId: "b76525a5-3c01-4e66-82a6-d0023c6ae463",
    target: formId1ElementTarget,
  };
  const form2 = {
    portalId: window.ajax_object?.hubspot_portal || "",
    formId: "d1c4a3d4-df22-42e8-bb1d-6e78a0a6ece8",
    target: formId2ElementTarget,
  };

  function inicializarForm1() {
    scstfm_logDebugFront("Inicializando Form1 do HubSpot...");

    hbspt.forms.create({
      portalId: form1.portalId,
      formId: form1.formId,
      target: form1.target,

      onFormReady: function ($form) {
        scstfm_logDebugFront("Form1 onFormReady => ok!");
        formInstances.form1 = $form;

        // Capturar o e-mail digitado e armazenar em localStorage
        const $inputEmail = $form.find('input[name="email"]');
        $inputEmail.on("input", (evt) => {
          const digitado = evt.target.value || "";
          window.localStorage.setItem("scstfm_temp_email", digitado);
          // Limpa eventual mensagem de erro
          scstfm_renderizarFeedbacks("#fcs-submit-formhub-email-error", "", "");
        });
      },

      onFormSubmitted: async function ($form) {
        scstfm_logDebugFront("Form1 onFormSubmitted => validando plugin...");

        // Lê o e-mail do localStorage (pois o input pode ter sido removido)
        const emailDigitado = window.localStorage.getItem("scstfm_temp_email") || "";
        scstfm_logDebugFront("Email recuperado do localStorage:", emailDigitado);

        // 1) Valida email
        const validEmail = await scstfm_validarEmail(emailDigitado);
        if (!validEmail) {
          scstfm_renderizarFeedbacks(
            "#fcs-submit-formhub-email-error",
            "Por favor utilize um e-mail corporativo",
            "color:red;"
          );
          // Recria o form
          document.querySelector(form1.target).innerHTML = "";
          inicializarForm1();
          return;
        }

        // 2) Checa créditos
        const creditoResponse = await scstfm_checarCreditos(emailDigitado);
        if (!creditoResponse.validated) {
          // Usa a mensagem que veio da API (ex: "Email já utilizado" ou "Créditos usados maior que 2")
          scstfm_renderizarFeedbacks("#fcs-submit-formhub-email-error", creditoResponse.message, "color:red;");
          // Recria o form
          document.querySelector(form1.target).innerHTML = "";
          inicializarForm1();
          return;
        }

        // Se chegou aqui => e-mail e créditos OK
        scstfm_logDebugFront("Form1 plugin OK => avançando p/ Tela 2");

        // IMPORTANTE: Salva o e-mail validado final em localStorage["email"]
        window.localStorage.setItem("email", JSON.stringify(emailDigitado));

        // Remove o temporário
        window.localStorage.removeItem("scstfm_temp_email");

        // Ocultamos o container do form
        const emailContainer = document.getElementById("email-form-container");
        if (emailContainer) {
          emailContainer.style.display = "none";
        }

        // Avança para tela 2
        scstfm_navegacaoSecoes.gerenciarNavegacaoSecoes();
        scstfm_navegacaoSecoes.moverParaProximaSecao();
      },
    });
  }

  function inicializarForm2() {
    scstfm_logDebugFront("Inicializando Form2 (SLA) do HubSpot...");
    hbspt.forms.create({
      portalId: form2.portalId,
      formId: form2.formId,
      target: form2.target,

      onFormReady: function ($form) {
        scstfm_logDebugFront("Form2 (SLA) pronto!");
        formInstances.form2 = $form;
      },

      onFormSubmitted: function ($form) {
        // Se quiser sumir com "Obrigado" no form2
        const $hubspotForm2 = $form.closest("form");
        $hubspotForm2.removeClass("submitted");
        $hubspotForm2.find('[class*="submitted-message"], [class*="hs-form__thankyou"]').hide();
      },
    });
  }

  // Inicializa ambos
  inicializarForm1();
  inicializarForm2();
}
