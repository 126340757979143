// File: src/model/obterSla.js
import { scstfm_logDebugFront } from "../utils/logDebugFront.js";

export function scstfm_obterSla() {
  const form = document.getElementById("hsForm_d1c4a3d4-df22-42e8-bb1d-6e78a0a6ece8");
  if (!form) {
    scstfm_logDebugFront("Formulário SLA não encontrado.");
    return null;
  }
  const dadosSla = {
    email: form.querySelector('input[name="email"]')?.value || "",
    firstname: form.querySelector('input[name="firstname"]')?.value || "",
    cpf: form.querySelector('input[name="cpf"]')?.value || "",
    genero: form.querySelector('select[name="genero"]')?.value || "",
    phone: form.querySelector('input[name="phone"]')?.value || "",
    company: form.querySelector('input[name="company"]')?.value || "",
    cargo_rh: form.querySelector('select[name="cargo_rh"]')?.value || "",
    tamanho_de_empresa: form.querySelector('select[name="tamanho_de_empresa"]')?.value || "",
  };
  scstfm_logDebugFront("Dados SLA: ", dadosSla);
  return dadosSla;
}
