// File: src/model/checarCreditos.js
import { scstfm_logDebugFront } from "../utils/logDebugFront.js";

export async function scstfm_checarCreditos(email) {
  const data = new FormData();
  data.append("action", "scstfm_checar_credits");
  data.append("nonce", window.ajax_object.ajax_nonce);
  data.append("email", email);

  try {
    const response = await fetch(window.ajax_object.ajax_url, {
      method: "POST",
      credentials: "same-origin",
      body: data,
    });
    const result = await response.json();
    scstfm_logDebugFront("Retorno checarCreditos: ", result);

    // Retorna o objeto completo, ou seja, com "validated" e "message"
    return result?.data || { validated: false, message: "Erro desconhecido." };
  } catch (error) {
    scstfm_logDebugFront("Erro ao checar créditos: ", error);
    return { validated: false, message: "Erro na requisição de créditos." };
  }
}
