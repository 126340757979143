// File: src/controllers/main.js

import { scstfm_caracteristicas_profiler } from "../utils/caracteristicas_profiler.js";
import { scstfm_renderizarFormProfiler } from "../view/renderizarFormProfiler.js";
import { scstfm_renderizarFormHubs, formInstances } from "../view/renderizarFormHubs.js";
import { scstfm_renderizarFeedbacks } from "../view/renderizarFeedbacks.js";
import { scstfm_validarEmail } from "../model/validarEmail.js";
import { scstfm_checarCreditos } from "../model/checarCreditos.js";
import { scstfm_enviarProfiler } from "../model/enviarProfiler.js";
import { scstfm_atualizarCreditos } from "../model/atualizarCreditos.js";
import { scstfm_obterSla } from "../model/obterSla.js";
import { scstfm_obterAdjetivosSelecionados, scstfm_submeterFormularioHubspot } from "../utils/helpers.js";
import scstfm_navegacaoSecoes from "./gerenciarNavegacaoSecoes.js";
import { scstfm_logDebugFront } from "../utils/logDebugFront.js";

document.addEventListener("DOMContentLoaded", async () => {
  scstfm_logDebugFront("Plugin Solides Como Sou: iniciado.");

  // 1) Guardar data/hora de início
  const testStartTimeISO = new Date().toISOString();
  window.localStorage.setItem("testStartTime", JSON.stringify(testStartTimeISO));

  // 2) Esperar script do HubSpot
  try {
    await scstfm_esperarHubspotCarregar();
    scstfm_logDebugFront("HubSpot carregado. Vamos renderizar formulários...");

    // 3) Renderizar formulários HubSpot
    scstfm_renderizarFormHubs("#fcs-formhub1", "#fcs-formhub2");
    scstfm_logDebugFront("Formulários HubSpot renderizados.");

    // 4) Renderizar partes do Profiler
    scstfm_renderizarFormProfiler(
      scstfm_caracteristicas_profiler,
      "fcs-profiler-part1-container",
      "fcs-profiler-part1"
    );
    scstfm_renderizarFormProfiler(
      scstfm_caracteristicas_profiler,
      "fcs-profiler-part2-container",
      "fcs-profiler-part2"
    );

    // 5) Configurar listeners (inclusive para limpar erros ao digitar email)
    scstfm_configurarListeners();
  } catch (err) {
    scstfm_logDebugFront("Erro ao aguardar o HubSpot: ", err);
  }
});

/**
 * Aguarda até que window.hbspt.forms.create esteja disponível (ou timeout em 10s)
 */
function scstfm_esperarHubspotCarregar(timeout = 10000) {
  return new Promise((resolve, reject) => {
    const start = Date.now();
    function checar() {
      if (window.hbspt && window.hbspt.forms && typeof window.hbspt.forms.create === "function") {
        return resolve(true);
      }
      if (Date.now() - start > timeout) {
        return reject(new Error("Timeout ao esperar HubSpot carregar."));
      }
      setTimeout(checar, 100);
    }
    checar();
  });
}

function scstfm_configurarListeners() {
  // (3) Limpar mensagem de erro ao digitar no campo de e-mail do primeiro form
  const hubspotEmailInput = document.querySelector("#fcs-formhub1 input[name='email']");
  if (hubspotEmailInput) {
    hubspotEmailInput.addEventListener("change", () => {
      scstfm_renderizarFeedbacks("#fcs-submit-formhub-email-error", "", "");
    });
  }

  // ========== TELA 1: Botão "Começar Profiler" ==========
  // Ele agora apenas submete o formulário HubSpot.
  const btnSubmitFormHubEmail = document.getElementById("fcs-submit-formhub-email");
  if (btnSubmitFormHubEmail) {
    btnSubmitFormHubEmail.addEventListener("click", (e) => {
      e.preventDefault();
      // Limpa feedback
      scstfm_renderizarFeedbacks("#fcs-submit-formhub-email-error", "", "");
      // Dispara submissão do form1 HubSpot
      scstfm_submeterFormularioHubspot(1);
    });
  }

  // ========== TELA 2: Profiler parte 1 ==========
  const btnNextSubsection1 = document.getElementById("fcs-btn-next-subsection-1");
  if (btnNextSubsection1) {
    btnNextSubsection1.addEventListener("click", (e) => {
      e.preventDefault();
      scstfm_renderizarFeedbacks("#fcs-screen2-error", "", "color:red;");

      const selecionados = scstfm_obterAdjetivosSelecionados("fcs-profiler-part1");
      const totalSubs = scstfm_navegacaoSecoes.totalSubsecoes;

      // Se já é a última sub-seção, precisamos checar se há 5 selecionados
      if (scstfm_navegacaoSecoes.indiceSubsecaoAtual === totalSubs - 1) {
        if (selecionados.length < 5) {
          scstfm_renderizarFeedbacks(
            "#fcs-screen2-error",
            "Por favor, selecione pelo menos 5 adjetivos.",
            "color:red;"
          );
          return;
        }
      }

      window.localStorage.setItem("adjetivosEuSou", JSON.stringify(selecionados));
      scstfm_navegacaoSecoes.moverParaProximaSubsecao("fcs-profiler-part1");
    });
  }

  // ========== TELA 3: Profiler parte 2 ==========
  const btnNextSubsection2 = document.getElementById("fcs-btn-next-subsection-2");
  if (btnNextSubsection2) {
    btnNextSubsection2.addEventListener("click", (e) => {
      e.preventDefault();
      scstfm_renderizarFeedbacks("#fcs-screen3-error", "", "color:red;");

      const selecionados = scstfm_obterAdjetivosSelecionados("fcs-profiler-part2");
      const totalSubs = scstfm_navegacaoSecoes.totalSubsecoes;

      if (scstfm_navegacaoSecoes.indiceSubsecaoAtual === totalSubs - 1) {
        if (selecionados.length < 5) {
          scstfm_renderizarFeedbacks(
            "#fcs-screen3-error",
            "Por favor, selecione pelo menos 5 adjetivos.",
            "color:red;"
          );
          return;
        }
      }

      window.localStorage.setItem("adjetivosEuDeveriaSer", JSON.stringify(selecionados));
      scstfm_navegacaoSecoes.moverParaProximaSubsecao("fcs-profiler-part2");
    });
  }

  // ========== TELA 4: Botão "Enviar Formulário" ==========
  const btnSubmitForm2 = document.getElementById("fcs-btn-submit-form2");
  if (btnSubmitForm2) {
    btnSubmitForm2.addEventListener("click", async (e) => {
      e.preventDefault();
      scstfm_renderizarFeedbacks("#fcs-screen4-error", "", "color:red;");

      const dadosSla = scstfm_obterSla();
      if (!dadosSla) {
        scstfm_renderizarFeedbacks("#fcs-screen4-error", "Formulário final não encontrado ou inválido.", "color:red;");
        return;
      }

      // 1) Envia Profiler
      const envioOk = await scstfm_enviarProfiler(dadosSla);
      if (!envioOk) {
        scstfm_renderizarFeedbacks("#fcs-screen4-error", "Erro ao enviar respostas do Profiler.", "color:red;");
      }

      // 2) Atualiza créditos
      const email = JSON.parse(localStorage.getItem("email") || '""');
      const credOk = await scstfm_atualizarCreditos(email);
      if (!credOk) {
        scstfm_renderizarFeedbacks("#fcs-screen4-error", "Erro ao atualizar créditos.", "color:red;");
      }

      // 3) Submete form2 do HubSpot
      if (formInstances.form2) {
        scstfm_logDebugFront("Submetendo formulário 2 (SLA)...");
        scstfm_submeterFormularioHubspot(2);
      }

      // 4) Remove o botão após a submissão
      if (btnSubmitForm2) btnSubmitForm2.remove();

      // 5) Se quiser avançar para alguma "próxima tela", chamaria:
      scstfm_navegacaoSecoes.moverParaProximaSecao();
    });
  }
}
