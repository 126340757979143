// File: src/model/enviarProfiler.js
import { scstfm_caracteristicas_profiler } from "../utils/caracteristicas_profiler.js";
import { scstfm_obterTimestampFormatado } from "../utils/helpers.js";
import { scstfm_logDebugFront } from "../utils/logDebugFront.js";

export async function scstfm_enviarProfiler(dadosSla) {
  // Recupera o e-mail
  const email = JSON.parse(localStorage.getItem("email") || '""');
  scstfm_logDebugFront("Email recuperado: ", email);

  // Recupera lista de adjetivos
  const adjetivosEuSou = JSON.parse(localStorage.getItem("adjetivosEuSou") || "[]");
  const adjetivosEuDeveriaSer = JSON.parse(localStorage.getItem("adjetivosEuDeveriaSer") || "[]");

  // Mapeia adjetivos em português para inglês
  const mapearAdjetivosParaIngles = (lista) => {
    return lista.map((adjPt) => {
      const found = scstfm_caracteristicas_profiler.find((item) => item.value === adjPt);
      return found ? found.english : adjPt;
    });
  };

  const adjetivosEuSouIngles = mapearAdjetivosParaIngles(adjetivosEuSou);
  const adjetivosEuDeveriaSerIngles = mapearAdjetivosParaIngles(adjetivosEuDeveriaSer);

  // Recupera a hora de início do teste (corrigindo para JSON.parse, pois foi salvo com JSON.stringify)
  const testStartTimeRaw = window.localStorage.getItem("testStartTime");
  const testStartTimeISO = testStartTimeRaw ? JSON.parse(testStartTimeRaw) : "";
  console.log("testStartTimeISO (corrigido): ", testStartTimeISO);

  // Formata tempos
  console.log(scstfm_obterTimestampFormatado(testStartTimeISO));
  const testEndTimeISO = new Date().toISOString();

  // Monta o objeto de dados para envio
  const dados = {
    iAmChosenAdjectives: adjetivosEuSouIngles,
    testStartTime: scstfm_obterTimestampFormatado(testStartTimeISO),
    testEndTime: scstfm_obterTimestampFormatado(testEndTimeISO),
    shouldBeChosenAdjectives: adjetivosEuDeveriaSerIngles,
    picture: "",
    name: dadosSla.firstname,
    email: email,
    gender: dadosSla.genero,
    idNumber: dadosSla.cpf,
    company: dadosSla.company,
    phone: dadosSla.phone,
  };

  scstfm_logDebugFront("Payload a enviar no AJAX: ", dados);

  // Prepara o FormData para envio via fetch
  const dataForm = new FormData();
  dataForm.append("action", "scstfm_enviar_profiler");
  dataForm.append("nonce", window.ajax_object.ajax_nonce);
  dataForm.append("dados", JSON.stringify(dados));

  try {
    const response = await fetch(window.ajax_object.ajax_url, {
      method: "POST",
      credentials: "same-origin",
      body: dataForm,
    });
    const result = await response.json();

    scstfm_logDebugFront("Retorno enviarProfiler: ", result);
    if (result?.data?.validated) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    scstfm_logDebugFront("Erro ao enviar profiler: ", error);
    return false;
  }
}
