// File: src/controller/gerenciarNavegacaoSecoes.js
import { scstfm_logDebugFront } from "../utils/logDebugFront.js";

/**
 * Este objeto gerencia a mudança de seções e subseções,
 * mas não coordena o fluxo do plugin (isso está em main.js).
 */
const scstfm_navegacaoSecoes = {
  indiceSecaoAtual: 0,
  secoes: null,
  indiceSubsecaoAtual: 0,
  subsecoes: [],
  totalSubsecoes: 0,

  gerenciarNavegacaoSecoes: function () {
    this.secoes = document.querySelectorAll("#fcs-sections-container section");
    this.secoes.forEach((secao) => {
      secao.classList.remove("ativa", "saindo");
      secao.style.display = "none";
    });
    this.secoes[this.indiceSecaoAtual].style.display = "block";
    this.secoes[this.indiceSecaoAtual].offsetHeight;
    this.secoes[this.indiceSecaoAtual].classList.add("ativa");
    this.inicializarSubsecao();
  },

  inicializarSubsecao: function () {
    const currentSection = this.secoes[this.indiceSecaoAtual];
    this.subsecoes = currentSection.querySelectorAll(".fcs-profiler-subsection");
    this.totalSubsecoes = this.subsecoes.length;
    this.indiceSubsecaoAtual = 0;
    if (this.totalSubsecoes > 0) {
      this.subsecoes.forEach((subsecao, index) => {
        subsecao.classList.toggle("active", index === 0);
      });
      const profiler_part = currentSection.getAttribute("data-profiler-part");
      this.atualizarProgressBar(profiler_part);
    } else {
      const progressBar = currentSection.querySelector(".fcs-progress");
      if (progressBar) {
        progressBar.style.display = "none";
      }
      this.indiceSubsecaoAtual = -1;
      this.subsecoes = [];
      this.totalSubsecoes = 0;
    }
  },

  moverParaProximaSecao: function () {
    if (this.indiceSecaoAtual < this.secoes.length - 1) {
      const oldSection = this.secoes[this.indiceSecaoAtual];
      const newSection = this.secoes[this.indiceSecaoAtual + 1];
      oldSection.classList.add("saindo");
      const onTransitionEnd = () => {
        oldSection.removeEventListener("transitionend", onTransitionEnd);
        oldSection.classList.remove("ativa", "saindo");
        oldSection.style.display = "none";
        newSection.style.display = "block";
        newSection.offsetHeight;
        newSection.classList.add("ativa");
        window.scrollTo(0, 0);
        this.indiceSecaoAtual++;
        this.inicializarSubsecao();
      };
      oldSection.addEventListener("transitionend", onTransitionEnd, { once: true });
      requestAnimationFrame(() => {
        oldSection.classList.remove("ativa");
      });
    }
  },

  moverParaSecaoAnterior: function () {
    if (this.indiceSecaoAtual > 0) {
      const oldSection = this.secoes[this.indiceSecaoAtual];
      const newSection = this.secoes[this.indiceSecaoAtual - 1];
      oldSection.classList.add("saindo");
      const onTransitionEnd = () => {
        oldSection.removeEventListener("transitionend", onTransitionEnd);
        oldSection.classList.remove("ativa", "saindo");
        oldSection.style.display = "none";
        newSection.style.display = "block";
        newSection.offsetHeight;
        newSection.classList.add("ativa");
        this.indiceSecaoAtual--;
        this.inicializarSubsecao();
      };
      oldSection.addEventListener("transitionend", onTransitionEnd, { once: true });
      requestAnimationFrame(() => {
        oldSection.classList.remove("ativa");
      });
    }
  },

  moverParaProximaSubsecao: function (profiler_part) {
    if (this.indiceSubsecaoAtual < this.totalSubsecoes - 1) {
      this.subsecoes[this.indiceSubsecaoAtual].classList.remove("active");
      this.indiceSubsecaoAtual++;
      this.subsecoes[this.indiceSubsecaoAtual].classList.add("active");
      this.atualizarProgressBar(profiler_part);
    } else {
      this.moverParaProximaSecao();
    }
  },

  moverParaSubsecaoAnterior: function (profiler_part) {
    if (this.indiceSubsecaoAtual > 0) {
      this.subsecoes[this.indiceSubsecaoAtual].classList.remove("active");
      this.indiceSubsecaoAtual--;
      this.subsecoes[this.indiceSubsecaoAtual].classList.add("active");
      this.atualizarProgressBar(profiler_part);
    } else {
      this.moverParaSecaoAnterior();
    }
  },

  atualizarProgressBar: function (profiler_part) {
    const progressBar = document.getElementById(`progress-bar-${profiler_part}`);
    if (progressBar) {
      const progress = ((this.indiceSubsecaoAtual + 1) / this.totalSubsecoes) * 100;
      progressBar.style.width = `${progress}%`;
    }
  },

  exibirMensagemErro: function (elementoId, mensagem) {
    const elemento = document.getElementById(elementoId);
    if (elemento) {
      elemento.textContent = mensagem;
      elemento.style.color = "red";
    }
  },
};

export default scstfm_navegacaoSecoes;
