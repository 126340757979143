// File: src/model/validarEmail.js
import { scstfm_logDebugFront } from "../utils/logDebugFront.js";

export async function scstfm_validarEmail(email) {
  scstfm_logDebugFront("🚀Iniciando validação de Email");
  const formData = new FormData();
  formData.append("action", "scstfm_validar_email");
  formData.append("nonce", window.ajax_object.ajax_nonce);
  formData.append("email", email);

  try {
    const requestValidarEmail = await fetch(window.ajax_object.ajax_url, {
      method: "POST",
      credentials: "same-origin",
      body: formData,
    });
    const response = await requestValidarEmail.json();
    scstfm_logDebugFront("Retorno da validação de email: ", response);

    if (response?.data?.validated) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    scstfm_logDebugFront("Erro na chamada de validarEmail.js para validar-email.php: ", error);
    return false;
  }
}
