// File: src/utils/helpers.js
import { formInstances } from "../view/renderizarFormHubs.js";
import { scstfm_logDebugFront } from "./logDebugFront.js";

/**
 * Retorna array de adjetivos selecionados no profiler_part.
 */
export function scstfm_obterAdjetivosSelecionados(profiler_part) {
  const checkboxes = document.querySelectorAll(`.${profiler_part} input[type="checkbox"]`);
  return Array.from(checkboxes)
    .filter((cb) => cb.checked)
    .map((cb) => cb.value);
}

/**
 * Submete (programaticamente) um form do HubSpot:
 * formNumber=1 => form1 (email)
 * formNumber=2 => form2 (SLA)
 */
export function scstfm_submeterFormularioHubspot(formNumber) {
  let targetFormId = "";
  if (formNumber === 1) {
    targetFormId = "hsForm_b76525a5-3c01-4e66-82a6-d0023c6ae463";
  } else if (formNumber === 2) {
    targetFormId = "hsForm_d1c4a3d4-df22-42e8-bb1d-6e78a0a6ece8";
  }

  if (!targetFormId) {
    scstfm_logDebugFront("ERROR: FormNumber inválido.");
    return;
  }
  const formElem = document.getElementById(targetFormId);
  if (!formElem) {
    scstfm_logDebugFront("ERROR: Formulário " + targetFormId + " não encontrado.");
    return;
  }
  // Força clique no botão de submit do HubSpot
  const submitButton = formElem.querySelector('.hs-submit input[type="submit"]');
  if (submitButton) {
    submitButton.click();
  } else {
    scstfm_logDebugFront("ERROR: Botão de submit não encontrado no form HS: " + targetFormId);
  }
}

/**
 * Formata ISO string em 'YYYY-MM-DD HH:mm:ss'
 */
export function scstfm_obterTimestampFormatado(timestampISO) {
  if (!timestampISO) return "Data inválida";
  const date = new Date(timestampISO);
  if (isNaN(date.getTime())) return "Data inválida";

  const ano = date.getFullYear();
  const mes = String(date.getMonth() + 1).padStart(2, "0");
  const dia = String(date.getDate()).padStart(2, "0");
  const hora = String(date.getHours()).padStart(2, "0");
  const min = String(date.getMinutes()).padStart(2, "0");
  const seg = String(date.getSeconds()).padStart(2, "0");
  return `${ano}-${mes}-${dia} ${hora}:${min}:${seg}`;
}
