// File: src/view/renderizarFormProfiler.js
import { scstfm_logDebugFront } from "../utils/logDebugFront.js";

export function scstfm_renderizarFormProfiler(caracteristicas_profiler, targetContainerId, profiler_part) {
  scstfm_logDebugFront("Iniciando renderizarFormProfiler...");
  try {
    let estadosCheckboxes = {};

    function renderizarFormulario() {
      scstfm_logDebugFront("Executando renderizarFormulario interno...");
      // Salva estado atual
      const inputs = document.querySelectorAll(`#${targetContainerId} input[type="checkbox"]`);
      inputs.forEach((input) => {
        estadosCheckboxes[input.id] = input.checked;
      });

      const targetElement = document.getElementById(targetContainerId);
      if (!targetElement) {
        scstfm_logDebugFront(`❌Elemento '${targetContainerId}' não encontrado no DOM`);
        return;
      }

      // Ordena em ordem alfabética (pt-BR)
      const caracteristicasOrdenadas = [...caracteristicas_profiler].sort((a, b) =>
        a.value.localeCompare(b.value, "pt-BR")
      );

      const isMobile = window.innerWidth < 768;
      scstfm_logDebugFront(`Mobile? ${isMobile}`);
      let containerHTML = "";

      if (isMobile) {
        // Versão mobile -> 2 subseções (44 e 42) -> total 86 adjetivos
        const chunkSizes = [44, 42];
        let startIndex = 0;
        const subsectionsHTML = chunkSizes.map((size, subsectionIndex) => {
          const subsectionItems = caracteristicasOrdenadas.slice(startIndex, startIndex + size);
          startIndex += size;

          // Duas colunas
          const columns = [[], []];
          subsectionItems.forEach((item, idx) => {
            columns[idx % 2].push(item);
          });

          const columnsHTML = columns
            .map(
              (col) => `
                <div class="fcs-profiler-column">
                  ${col
                    .map(
                      (item) => `
                      <div class="fcs-checkbox-container ${profiler_part}" data-index="${item.value}">
                        <input type="checkbox" id="${profiler_part}-${subsectionIndex}-${item.value}" 
                               name="${profiler_part}" value="${item.value}" />
                        <label for="${profiler_part}-${subsectionIndex}-${item.value}">${item.value}</label>
                      </div>
                    `
                    )
                    .join("")}
                </div>
              `
            )
            .join("");

          return `
            <div class="fcs-profiler-subsection ${
              subsectionIndex === 0 ? "active" : ""
            }" data-subsection-index="${subsectionIndex}">
              <div id="fcs-profiler-container">
                ${columnsHTML}
              </div>
            </div>
          `;
        });

        const progressBarHTML = `
          <div class="fcs-progress">
            <div class="fcs-progress-bar" id="progress-bar-${profiler_part}" style="width: ${
          100 / chunkSizes.length
        }%"></div>
          </div>
        `;

        containerHTML = `
          ${progressBarHTML}
          <div id="profiler-subsections-${profiler_part}">
            ${subsectionsHTML.join("")}
          </div>
        `;
      } else {
        // Versão desktop -> 5 colunas
        const columnSizes = [18, 17, 17, 17, 17];
        let startIndex = 0;

        const columnsHTML = columnSizes.map((size) => {
          const colItems = caracteristicasOrdenadas.slice(startIndex, startIndex + size);
          startIndex += size;
          const itemsHTML = colItems
            .map(
              (item) => `
                <div class="fcs-checkbox-container ${profiler_part}" data-index="${item.value}">
                  <input type="checkbox" id="${profiler_part}-${item.value}" 
                         name="${profiler_part}" value="${item.value}" />
                  <label for="${profiler_part}-${item.value}">${item.value}</label>
                </div>
              `
            )
            .join("");
          return `<div class="fcs-profiler-column">${itemsHTML}</div>`;
        });

        containerHTML = `
          <div id="fcs-profiler-container">
            ${columnsHTML.join("")}
          </div>
        `;
      }

      targetElement.innerHTML = containerHTML;
      scstfm_logDebugFront("HTML do formulário gerado com sucesso.");

      // Restaura checkboxes selecionados
      const checkboxContainers = targetElement.querySelectorAll(`.${profiler_part}`);
      checkboxContainers.forEach((container) => {
        const input = container.querySelector('input[type="checkbox"]');
        if (estadosCheckboxes.hasOwnProperty(input.id)) {
          input.checked = estadosCheckboxes[input.id];
          container.classList.toggle("selected", input.checked);
        }
        container.addEventListener("click", () => {
          input.checked = !input.checked;
          container.classList.toggle("selected", input.checked);
        });
      });
    }

    // Renderização inicial
    renderizarFormulario();
    scstfm_logDebugFront("Formulário de adjetivos renderizado.");

    // Re-render ao redimensionar
    window.addEventListener("resize", () => {
      scstfm_logDebugFront("Evento resize -> re-render do formulário de adjetivos");
      renderizarFormulario();
    });

    return true;
  } catch (error) {
    scstfm_logDebugFront("❌Erro em scstfm_renderizarFormProfiler: " + error);
    return false;
  }
}
